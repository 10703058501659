import * as React from 'react';
import { Helmet } from 'react-helmet';

const supportLanguages = ['ko', 'en', 'ja', 'th', 'zh-Hant'];

const Page: React.FC = () => {
  const [language] = typeof window !== 'undefined' ?
    window.navigator.language.split('-') : ['en'];
  const redirectLanguage =
    supportLanguages.find(x => x.startsWith(language)) || supportLanguages[0];
  const redirectURL = redirectLanguage === 'ko' ?
  'https://devsisters.notion.site/5ba3adfb76a94731853fae55b5a5b44c'
  : 'https://devsisters.notion.site/fb5915b846de408fb0816bbe7ecfae2a';
  React.useEffect(() => {
    window.location.href = redirectURL;
  }, []);
  return (
    <Helmet>
      <meta property="og:url" content={redirectURL} />
      <link rel="canonical" href={redirectURL} />
    </Helmet>
  );
};

export default Page;
